import React, { Fragment, useState, useEffect } from 'react';
import { Element, Link as LinkElemet } from 'react-scroll';
import Header from '@components/header';
import Button from '@components/button';
import Information from '@components/information';
import Icon from '@components/Icon';
import CardMain from '@components/card-main';
import FooterLanding from '@components/footer';
import { HOME } from '@information-texts/HomeComplement';
import { IOptionSelect } from '@components/input';
import { ModalSave } from '@components/modal';
import CarouselTestimony from './components/CarouselTestimony';
import { IDataForm, INITIAL_DATA_FORM } from './components';
import Form from './components/Form';
import { aboutUs } from '../../services/aboutUs';
import { SUCCESS_RESPONSE } from '../../constants/StatusCode';
import { AboutUs } from './components/about-us';
import { dataCardServices, constant, textDescriptions, dataCardTestimony, iconsOurClients } from '.';
import './Home.scss';

const Home: React.FC = () => {
    const [sizeScreen, setSizeScreen] = useState(screen.width);
    const [showModalSave, setShowModalSave] = useState(false);
    const [dataForm, setDataForm] = useState<IDataForm>(INITIAL_DATA_FORM);
    const [validateForm, setValidateForm] = useState(false);
    const [activeValidate, setActiveValidate] = useState(false);

    window.addEventListener('resize', (): void => {
        setSizeScreen(screen.width);
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setDataForm({ ...dataForm, [name]: value });
    };

    const handleChangeSelect = (option: IOptionSelect): void => {
        setDataForm({ ...dataForm, affair: option.value });
    };

    useEffect(() => {
        setValidateForm(Object.values(dataForm).some(value => value === ''));
    }, [dataForm]);

    const saveData = async (): Promise<void> => {
        if (!validateForm) {
            const status = await aboutUs(dataForm);
            if (SUCCESS_RESPONSE.includes(Number(status))) {
                setDataForm(INITIAL_DATA_FORM);
                setActiveValidate(false);
                setShowModalSave(true);
            }
        } else {
            setActiveValidate(true);
        }
    };

    return (
        <>
            <ModalSave
                showModal={showModalSave}
                setShowModal={(): void => {
                    setShowModalSave(!showModalSave);
                }}
            />
            <Header />
            <div className="flex flex-col items-center justify-start">
                <div className="section-description">
                    <Information description={HOME.DESCRIPTION_HEAD_COMPLEMENT} classNameContainer="xs:px-9.75" />
                    <LinkElemet
                        offset={-80}
                        smooth={true}
                        to="contact-us"
                        className="w-45.25 h-8.5 xs:w-37.5 xs:text-xs Link-btn cursor-pointer font-poppins-bold"
                    >
                        Contáctenos
                    </LinkElemet>
                </div>
                <Element name="our-solution" className="section-solutions">
                    <Information
                        title="Nuestras soluciones"
                        description={HOME.DESCRIPTION_OUR_SOLUTIONS}
                        classNameTitle="text-title-sm justify-center xs:text-xxl font-poppins-bold"
                        classNameContainer="xs:px-9.75 flex items-center flex-col"
                        color="blue"
                    />
                    <div className="flex items-end justify-center w-full max-h-141 mb-4.5 xs:flex-col mt-8">
                        <Icon name="pcTablet" className="lg:ml-49" />
                        <div className="description-diggipymes">
                            <Information
                                title="diggi pymes"
                                description={textDescriptions.descriptionDiggipymes}
                                classNameTitle="font-semibold xs:w-full xs:justify-center !text-3xl leading-10 mb-3.25"
                                classNameContainer="xs:w-full xs:text-center xs:text-sm "
                                color="blue"
                            />
                            <Button
                                text="¡Conócelo!"
                                classContainer="bg-green text-base mt-3.25 w-45.25 h-8.5 xs:w-37.5 xs:text-xs font-poppins font-semibold"
                                link={constant.pathLandingDiggipymes}
                                target="_blank"
                                outside
                            />
                        </div>
                    </div>
                </Element>
                <Element className="flex flex-col w-full item-center bg-gray-light" name="services">
                    <Information
                        title="Servicios para su empresa"
                        description="Desarrollo de software y consultoría"
                        classNameTitle="text-title-sm font-poppins-bold justify-center xs:text-xxl xs:text-center xs:landing-8 mt-12.25 mb-5"
                        classNameDescription="text-center font-medium text-lg xs:text-sm"
                        color="blue"
                    />
                    <div className="container-software-development">
                        <div className="xs:flex xs:justify-center xs:h-full mr-13 xs:mr-0">
                            <Information
                                title="Desarrollo de software "
                                description={HOME.DESCRIPTION_SOFTWARE}
                                classNameTitle="text-2.45xl font-bold xs:text-lg w-full xs:font-semibold xs:justify-center"
                                classNameContainer="max-w-86.25 xs:mb-7"
                                classNameDescription="xs:text-center xs:font-medium xs:text-sm"
                                color="blue"
                            />
                            <LinkElemet
                                smooth={true}
                                to="contact-us"
                                className="w-45.25 h-8.5 mt-7 xs:absolute xs:-bottom-17.5 Link-btn cursor-pointer font-medium text-base"
                                offset={-80}
                            >
                                Contáctenos
                            </LinkElemet>
                        </div>
                        <div className="relative">
                            <Icon name="imageDesktopDiggipymes" className="relative w-full lg:h-98.75 max-w-160" />
                        </div>
                    </div>
                    <div className="container-consultancy">
                        <div className="container-consultancy__container--cards">
                            {sizeScreen < constant.sizeScreen ? (
                                dataCardServices.map((dataCard, index) => (
                                    <CardMain
                                        data={[dataCard]}
                                        classContainerCard="container-consultancy__cards--responsive xs:animate-automatic-image"
                                        classTitle="container-consultancy__title--responsive"
                                        classContainerIcon="container-consultancy__icon--responsive"
                                        key={index}
                                        classIcon="w-12.5 h-12.5"
                                    />
                                ))
                            ) : (
                                <CardMain
                                    data={dataCardServices}
                                    classContainerCard="container-consultancy__cards"
                                    classTitle="container-consultancy__title"
                                    classContainerIcon="container-consultancy__container--icon"
                                    classIcon="w-12.5 h-12.5"
                                />
                            )}
                        </div>
                        <div className="xs:flex xs:flex-col xs:items-center xs:relative ">
                            <Information
                                title="Consultorías"
                                description={HOME.DESCRIPTION_CONSULTANCY}
                                classNameTitle="text-2.45xl font-bold xs:text-lg font-poppins font-semibold"
                                classNameContainer="max-w-86.25 xs:flex xs:flex-col xs:items-center"
                                classNameDescription="xs:text-center xs:text-sm"
                                color="blue"
                            />
                            <LinkElemet
                                smooth={true}
                                to="contact-us"
                                className="w-45.25 h-8.5 text-base mt-7 xs:absolute xs:-bottom-48 xs:mt-6.5 Link-btn cursor-pointer font-semibold"
                                offset={-80}
                            >
                                Contáctenos
                            </LinkElemet>
                        </div>
                    </div>
                    <div className="flex flex-col items-center xs:mt-23.5 mt-18.24 mb-15.36">
                        <Information
                            title="Nuestros clientes"
                            classNameTitle="text-title-sm font-poppins-bold xs:text-lg mb-8.75 xs:mb-6.75"
                            color="blue"
                        />
                        <div className="flex items-center justify-center xs:flex-wrap gap-x-14 gap-y-10">
                            {iconsOurClients.map((icon, key) => (
                                <Icon
                                    {...icon}
                                    className="max-w-50 lg:w-full lg:h-full max-h-17.5 xs:mt-4.75"
                                    key={`${icon.name}-${key}`}
                                />
                            ))}
                        </div>
                    </div>
                </Element>
                <div className="flex flex-col items-center justify-center w-full h-full bg-gray-background">
                    <Information
                        title="Testimonios"
                        description="Conozca los testimonios de nuestros clientes."
                        classNameTitle="text-title-sm font-poppins-bold xs:text-xxl justify-center xs:text-blue mt-17.25 mb-3 leading-9"
                        classNameDescription="text-lg xs:text-sm xs:w-62.5 xs:text-center"
                        color="blue"
                    />
                    <CarouselTestimony sizeScreen={sizeScreen} dataCarousel={dataCardTestimony} />
                </div>
                <Element
                    className="flex flex-col items-center justify-center w-full h-full bg-gray-light"
                    name="about-us"
                >
                    <Information
                        title="Sobre nosotros"
                        description={HOME.ABOUT_US}
                        classNameTitle="text-title-sm xs:text-blue font-poppins-bold xs:text-xxl xs:mt-0.5 justify-center"
                        classNameDescription="text-xl flex flex-col items-center "
                        classNameContainer="mt-8.75 xs:mt-5 flex flex-col items-center max-w-271.5 xs:px-9.75 md:mx-24.5"
                        color="blue"
                    />
                    <div className="mt-20 xs:mt-7 mb-13">
                        <AboutUs />
                    </div>
                </Element>
                <Element className="w-full xs:mb-0 bg-gray-background" name="contact-us">
                    <Information
                        title="Contáctenos"
                        description="¡Nuestro equipo está disponible para ayudarle con cualquier duda que tenga!"
                        classNameTitle="text-title-sm xs:text-xxl justify-center mb-4.5 font-poppins-bold mt-10.75"
                        classNameDescription="text-xl flex flex-col items-center text-blue xs:text-sm xs:w-full xs:text-center"
                        classNameContainer="flex flex-col items-center xs:px-6 md:mx-24.5"
                        color="blue"
                    />
                    <div className="flex justify-center mx-4 mb-16 xs:flex-col">
                        <Information
                            title=""
                            description={HOME.CONTACT_US}
                            classNameTitle="text-3.5xl xs:text-blue font-bold xs:text-xxl xs:mt-0.5 justify-center"
                            classNameDescription="text-xl flex flex-col items-start xs:w-full"
                            classNameContainer="mt-11.75 xs:mt-2 flex flex-col items-start max-w-105.25 xs:mb-2.5"
                        />
                        <div className="xs:mt-0 mt-7 xs:ml-0 ml-22.5" id="contact">
                            <Form
                                handleChange={handleChange}
                                dataForm={dataForm}
                                handleChangeSelect={handleChangeSelect}
                                activeValidate={activeValidate}
                                onClickSave={(): void => {
                                    saveData();
                                }}
                            />
                        </div>
                    </div>
                </Element>
            </div>
            <FooterLanding sizeScreen={sizeScreen} />
        </>
    );
};

export default Home;
